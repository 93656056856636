<template>
    <div class="vue-box">

        <div class="header">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>项目实施</el-breadcrumb-item>
                <el-breadcrumb-item>项目详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="title">施工质量检查</div>

        <div class="c-panel">
            <el-row justify="center">
                <el-col :span="5">
                    <el-row>
                        <el-col>项目名称</el-col>
                        <el-col style="font-weight: bold;margin-top: 10px;">{{one.name}}</el-col>
                    </el-row>
                </el-col>
                <el-col :span="5">
                    <el-row>
                        <el-col>记录日期：</el-col>
                        <el-col style="font-weight: bold;margin-top: 10px;">{{one.createTime}}</el-col>
                    </el-row>
                </el-col>
                <el-col :span="5">
                    <el-row>
                        <el-col>记录人员：</el-col>
                        <el-col style="font-weight: bold;margin-top: 10px;">{{one.creatorName}}</el-col>
                    </el-row>
                </el-col>
                <el-col :span="9">
                </el-col>
            </el-row>
        </div>

        <div class="c-panel">
            <el-descriptions title="检查信息" :column="2" border>
                <el-descriptions-item label="检查项目">{{one.name}}</el-descriptions-item>
                <el-descriptions-item label="是否合格">{{one.qualified?"合格":"不合格"}}</el-descriptions-item>
                <el-descriptions-item label="问题部位">{{one.issue}}</el-descriptions-item>
                <el-descriptions-item label="是否需要整改">{{one.improve?"需要":"不需要"}}</el-descriptions-item>
                <el-descriptions-item label="备注" :span="2">
                    {{one.info}}
                </el-descriptions-item>
                <el-descriptions-item label="上传图片" :span="2">
                    <el-image 
                        style="width: 100px; height: 100px"
                        :src="one.firstImg" 
                        :preview-src-list="srcList">
                    </el-image>
                </el-descriptions-item>

                 <el-descriptions-item label="上传附件" :span="2">
                    <el-link type="primary" v-for="item in one.filesFiles" :key="item.name"
                    :href="item.url">{{item.name}}</el-link>
                </el-descriptions-item>

                <el-descriptions-item label="验收人">{{one.checkName}}</el-descriptions-item>
                <el-descriptions-item label="验收日期">{{one.createTime}}</el-descriptions-item>
                <el-descriptions-item label="验收意见" :span="2">
                    {{one.suggest}}
                </el-descriptions-item>
            </el-descriptions>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            one: {},
            srcList:[],
            firstImg:""
        }
    },

    mounted() {
        this.one = this.sa_admin.params;
        console.log(5555555, this.one)
        this.one.filesFiles = JSON.parse(this.one.files);
        this.one.imgFiles = JSON.parse( this.one.img);
        
        this.srcList = this.one.imgFiles.map(item=>{return item.url});
        this.one.firstImg = this.srcList[0];
        
    },
}
</script>

<style>
.header {
    margin: 20px;
}

.title {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2d2d2d;
    line-height: 29px;
    padding-left: 20px;
    border-left: 3px solid #4983f4;
    margin-left: 20px;
}
</style>